import { Inject, Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(@Inject(DOCUMENT) private document: Document) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        
        const tokenElement = this.document.getElementsByName('__RequestVerificationToken')[0] as HTMLInputElement;
        const token = tokenElement ? tokenElement.value : "";

        // Clone the request and add the token to the headers
        const authReq = req.clone({
            headers: new HttpHeaders({
                'RequestVerificationToken': token,
            })
        });

        // Pass on the cloned request instead of the original request
        return next.handle(authReq);
    }
}