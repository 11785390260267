import { Pipe, PipeTransform } from '@angular/core';
import { Localization } from '../services/localization.service';

@Pipe({ name: 'stLoc' })
export class LocalizationPipe implements PipeTransform {

    constructor(
        private localizationService: Localization,
    ) {
    }
    
    transform(key: string) {
        return this.localizationService.get(key);
    }
}
