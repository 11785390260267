import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-qr-code-modal',
   template: require('./qr-code-modal.component.html')
})
export class QrCodeModalComponent implements OnInit {
  @Input() imageUrl: SafeUrl;
  @Output() onHide = new EventEmitter();

  public image: SafeUrl;

  @ViewChild('backdrop') backdrop: ElementRef;

  constructor(
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    this.renderer.listen('window', 'click',(e:Event)=>{
     if(e.target === this.backdrop?.nativeElement){
        this.onHide.emit();
     }
    });
  }
}
