import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CardListComponent } from './pages/card-list/card-list.component';
import { CardDetailComponent } from './pages/card-detail/card-detail.component';
import { SendEmailComponent } from './pages/send-email/send-email.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';

const routes: Routes = [
    {
        path: 'visitenkarten',
        component: CardListComponent,
    },
    {
        path: 'visitenkarten/not-found',
        component: NotFoundComponent,
    },
    {
        path: 'visitenkarten/:id',
        component: CardDetailComponent,
    },
    {
        path: 'visitenkarten/:id/send-email',
        component: SendEmailComponent,
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
