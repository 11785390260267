import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientConfigWebProxy } from './client-config.webproxy.service';

export const CLIENT_CONFIG = 'clientConfig';

@Injectable()
export class ClientConfig {
    private clientConfigPromise: Promise<any>;

    constructor(
        private clientConfigWebProxy: ClientConfigWebProxy
    ) {
    }

    get$(): Observable<any> {      
        return this.clientConfigWebProxy.get$();
    }

    get(): Promise<any> {
        if (!this.clientConfigPromise) {
            this.clientConfigPromise = this.clientConfigWebProxy.get$().toPromise().then(data => data?.model);
        }
        return this.clientConfigPromise;
    }
}