import { Injectable } from "@angular/core";

@Injectable()
export class DeviceService {


    constructor() {}

    isAndroid = (): boolean => this.getMobileOperatingSystem() === "Android";
    isIOS = (): boolean => this.getMobileOperatingSystem() === "iOS";

    getMobileOperatingSystem() {
        var userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

        if (/android/i.test(userAgent)) {
            return "Android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if ([
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
          ].includes(navigator.platform)
          // iPad on iOS 13 detection
          || (navigator.userAgent.includes("Mac") && "ontouchend" in document)) {
            return "iOS";
        }

        return "unknown";
    }
}