import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { LocalizationPipe } from "./localization.pipe";
import { ValueToLabelPipe } from "./value-to-label.pipe";

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        LocalizationPipe,
        ValueToLabelPipe
    ],
    exports: [
        LocalizationPipe,
        ValueToLabelPipe
    ]
})
export class PipesModule {}