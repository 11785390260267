import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class HeaderService {

    private titleSubject = new Subject<string>();
    private backArrowSubject = new Subject<boolean>();

    constructor() {}

    setBackArrow(show: boolean) {
        this.backArrowSubject.next(show);
    }

    getBackArrow(): Observable<boolean> {
        return this.backArrowSubject.asObservable();
    }

    setTitle(title: string) {
        this.titleSubject.next(title);
    }

    getTitle(): Observable<string> {
        return this.titleSubject.asObservable();
    }
}

