import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { BusinessCardsService } from './services/bussines-cards.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HeaderService } from './services/header.service';
import { SharedServicesModule } from '../../../Core/services/shared.services.module';
import { GenericData } from './services/generic-data.service';
import { DeviceService } from './services/device.service';
import { CardTemplateComponent } from './pages/card-template/card-template.component';
import { SharedModule } from '../../../Core/shared.module';
import { CardDetailComponent } from './pages/card-detail/card-detail.component';
import { CardListComponent } from './pages/card-list/card-list.component';
import { SendEmailComponent } from './pages/send-email/send-email.component';
import { QrCodeModalComponent } from './pages/card-detail/qr-code-modal/qr-code-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { ClientConfig } from './services/client-config.service';
import { ClientConfigWebProxy } from './services/client-config.webproxy.service';
import { TokenInterceptor } from './services/token.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    CardTemplateComponent,
    CardDetailComponent,
    CardListComponent,
    SendEmailComponent,
    QrCodeModalComponent,
    NotFoundComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedServicesModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [BusinessCardsService, HeaderService, GenericData, DeviceService, ClientConfig, ClientConfigWebProxy,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
