import { Component, OnDestroy, OnInit } from '@angular/core';
import { BusinessCardsService, PersonBusinessCardListItem } from '../../services/bussines-cards.service';
import { Subscription } from 'rxjs';
import { HeaderService } from '../../services/header.service';
import { Localization } from '../../../../../Core/services/localization.service';

@Component({
  selector: 'app-card-list',
   template: require('./card-list.component.html'),
})
export class CardListComponent implements OnInit, OnDestroy {
  public cards: PersonBusinessCardListItem[] = [];
  private subsctiprions: Subscription[] = [];

  constructor(
    private bussinessCardsService: BusinessCardsService,
    private headerService: HeaderService,
    private localization: Localization
  ) { 
    this.headerService.setTitle(this.localization.get('portegoJS.businessCards_ListPageTitle'));
    this.headerService.setBackArrow(false);
  }

  ngOnInit() {
    this.getAllCards();
    
  }

  ngOnDestroy() {
    this.subsctiprions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  getAllCards() {
    this.subsctiprions.push(
      this.bussinessCardsService.getAll().subscribe((response) => {
        this.cards = response.model;
      })
    );
  }
}
