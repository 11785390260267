import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from '../../services/header.service';
import { BusinessCardsService, PersonBusinessCardListItem } from '../../services/bussines-cards.service';
import { DeviceService } from '../../services/device.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Subscription, switchMap, throwError } from 'rxjs';

@Component({
  selector: 'app-card-detail',
   template: require('./card-detail.component.html')
})
export class CardDetailComponent implements OnInit, OnDestroy {

  public cardId: string;
  public card: PersonBusinessCardListItem;
  public qrCodeUrl: SafeUrl;
  public showModal: boolean = false;
  private subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private headerService: HeaderService,
    private bussinessCardsService: BusinessCardsService,
    private deviceService: DeviceService,
    private sanitizer: DomSanitizer,
    private router: Router
  ) { 
    this.headerService.setBackArrow(true);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.route.paramMap.subscribe( paramMap => {
        this.cardId = paramMap.get('id') || '';
      }),

      this.bussinessCardsService.get(this.cardId).pipe(
        switchMap(card => {
          this.card = card.model;
          this.headerService.setTitle(this.card.label);
          return this.bussinessCardsService.generateQRCode(card.model.id)      
        }),
      ).subscribe({
        next: (response) => {
          let objectURL = URL.createObjectURL(response);       
          this.qrCodeUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        },
        error: (error) => this.handleError(error)
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  public isAndroid(): boolean {
    return this.deviceService.isAndroid();
  }

  public isIOS(): boolean {
    return this.deviceService.isIOS();
  }

  generateGoogleWalletPass() {
    this.subscriptions.push(
      this.bussinessCardsService.addPassToGoogleWallet(this.card.id).subscribe((response) => {
        window.location.href = response.model;
    }));
  }

  generateAppleWalletPass() {
    this.subscriptions.push(
      this.bussinessCardsService.addPassToAppleWallet(this.card.id).subscribe((response) => {
        const blob = new Blob([response], { type: 'application/vnd.apple.pkpass' });
        let downloadURL = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = downloadURL;
        link.download = "visitenkarte.pkpass";
        link.click();
    }));
  }

  private handleError(error: any) {
    if (error.status === 404) {
      this.router.navigate(['/visitenkarten/not-found']);
    }
    throwError(error.error);
  }

}
