import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';

@Component({
    selector: 'svg-icon',
    template: `
        <svg #svgEl [ngClass]="getClass()" style="{{getStyle()}}">
            <use [attr.xlink:href]="getLink(name)"></use>
        </svg>
    `,
})
export class SvgIconComponent implements OnInit {

    @Input() name: string;
    @Input() class: string;
    @Input() style: string;
    @ViewChild('svgEl') svgEl: ElementRef;

    private iconBase = 'sicon';

    constructor(
        private renderer: Renderer2,
        private elementRef: ElementRef,
    ) {
    }

    ngOnInit(): void {
        this.removeClassesOnWrappedElement();
        this.removeStyleAttributeOnWrappedElementIfDefined();
    }

    getLink(name: string): string {
        return '/Content/Images/sprite.svg#' + name;
    }

    getClass(): string {
        const classList = this.class ? this.class.split(' ') : [];
        if (!classList.includes(this.iconBase)) {
            classList.push(this.iconBase);
        }
        return classList.join(' ');
    }

    getStyle(): string {
        return this.style || '';
    }

    private removeClassesOnWrappedElement(): void {
        if (this.class) {
            const classList = this.class.split(' ');
            classList.filter(className => className.includes(this.iconBase)).forEach(className => {
                this.renderer.removeClass(this.elementRef.nativeElement, className);
            });
        }
    }

    private removeStyleAttributeOnWrappedElementIfDefined(): void {
        if (this.style) {
            this.renderer.removeAttribute(this.elementRef.nativeElement, 'style');
        }
    }
}
