import { Component } from '@angular/core';
import { HeaderService } from '../../services/header.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-not-found',
   template: require('./not-found.component.html'),
})
export class NotFoundComponent {

  constructor(
    private headerService: HeaderService,
    private router: Router) {
    
    this.headerService.setBackArrow(false);
  }

  backClicked() {
    this.router.navigate(['/visitenkarten']);
  }
}
