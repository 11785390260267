import { Injectable } from '@angular/core';

declare var LocalizationProvider: any;

export const LOCALIZATION = 'localization';
export const LOCALIZATION_TRANSLATIONS = 'localization.translations';

@Injectable()
export class LocalizationTranslations {
    getData(): any {
        let provider = new LocalizationProvider();
        return provider.getData();
    }
}

@Injectable()
export class Localization {
    constructor(
        private localizationTranslations: LocalizationTranslations,
    ) { }

    /**
     * @param templateValues replaces substrings {0}, {1}, .. with respective values in this array 
     */
    get(key: string, ...templateValues: string[]): string {
        let params = key.split('.');
        let translation = this.localizationTranslations.getData();

        for (let i = 0; i < params.length; i++) {
            translation = translation[params[i]];
        }

        if (translation && templateValues) {
            templateValues.forEach((value, index) => {
                translation = translation.replace(`{${index}}`, value);
            });
        }
        return translation;
    }
}
