import { NgModule } from '@angular/core';
import { PipesModule } from './pipes/pipes.module';
import { CommonModule } from '@angular/common';
import { SharedComponentsModule } from './components/shared.components.module';

@NgModule({
    imports: [
        CommonModule,
        PipesModule,
        SharedComponentsModule
    ],
    exports: [
        PipesModule,
        SharedComponentsModule
    ]
})
export class SharedModule { }
