import { Component, OnDestroy } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Subscription } from "rxjs";
import { Localization } from "../../../Core/services/localization.service";
import { ClientConfig } from "./services/client-config.service";

@Component({
    selector: 'app-root',
    template: `
        <app-card-template></app-card-template>
    `,
    styles: []
})
export class AppComponent implements OnDestroy{

    private subscription: Subscription;
    constructor(title: Title,
        clientConfigService: ClientConfig,
        localization: Localization
    ) {
        this.subscription = clientConfigService.get$().subscribe(data => {
            let developerAcronym = data?.model?.modules?.intranet?.developerAcronym;
            let pageTitle = localization.get('portegoJS.businessCards_ListPageTitle');
            title.setTitle(`${developerAcronym?.length > 0 ? ` ${developerAcronym} |` : ''} ${pageTitle}`);
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}