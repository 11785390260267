import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Subscription, mergeMap, of } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BusinessCardsService, SendVCardInputModel } from '../../services/bussines-cards.service';
import { ActivatedRoute } from '@angular/router';
import { HeaderService } from '../../services/header.service';

@Component({
  selector: 'send-email-template',
   template: require('./send-email.component.html')
})
export class SendEmailComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  emailForm = new FormGroup({
    emailFormControl: new FormControl('', [Validators.required, Validators.pattern('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,}$')])
  });

  constructor(private location: Location,
    private businessCardsService: BusinessCardsService,
    private route: ActivatedRoute,
    private headerService: HeaderService) {
      this.headerService.setBackArrow(true);
  }

  public ngOnInit() {
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  public onSubmit() {
    this.subscriptions.push(
      this.route.paramMap.pipe(
        mergeMap(paramMap => {
          let model: SendVCardInputModel = {
            idBusinessCard: Number(paramMap.get('id')),
            recipientEmail: this.emailForm.value.emailFormControl ?? ''
          };
          return of(model);
        }),
        mergeMap(model => this.businessCardsService.sendVCardToEmail(model)))
      .subscribe(res => {
        this.backClicked();
      })
    );
  }

  backClicked() {
    this.location.back();
  }

  public isEmailInvalid(): boolean | undefined{
    return this.emailForm.get('emailFormControl')?.invalid && this.emailForm.get('emailFormControl')?.touched;
  }

  public buttonDisabled(): boolean | undefined{
    return this.emailForm.get('emailFormControl')?.invalid
  }
}
