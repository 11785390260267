import { Injectable } from '@angular/core';
import { GenericData } from './generic-data.service';

export const CLIENT_CONFIG_WEB_PROXY = 'clientConfigWebProxy';

@Injectable()
export class ClientConfigWebProxy {
    constructor(private genericData: GenericData) { }

    get$ = () => this.genericData.getData$('/api/intranet/clientConfig/get');
}