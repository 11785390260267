import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericData } from './generic-data.service';

@Injectable({
    providedIn: 'root'
})
export class BusinessCardsService {
    private BASE_URL = 'api/intranet/businessCard';

    constructor(private genericData: GenericData) {}

    getAll = (): Observable<{model: PersonBusinessCardListItem[]}> => 
        this.genericData.getData$(`${this.BASE_URL}/getAll`);
    get = (idBusinessCard: string): Observable<{ model: PersonBusinessCardListItem }> => 
        this.genericData.getData$(`${this.BASE_URL}/get`, { idBusinessCard });
    generateQRCode = (idBusinessCard: number): Observable<Blob> => 
        this.genericData.postBlob$(`${this.BASE_URL}/generateQRCode`, { idBusinessCard }, { responseType: 'blob' });
    sendVCardToEmail = (inputModel: SendVCardInputModel): Observable<any> =>
        this.genericData.postData$(`${this.BASE_URL}/sendVCardToEmail`, inputModel);
    addPassToGoogleWallet = (idBusinessCard: number): Observable<{ model: string}> =>
        this.genericData.postData$(`${this.BASE_URL}/addPassToGoogleWallet`, { id: idBusinessCard });
    addPassToAppleWallet = (idBusinessCard: number): Observable<Blob> =>
        this.genericData.postBlob$(`${this.BASE_URL}/addPassToAppleWallet`, { id: idBusinessCard }, { responseType: 'blob' });
}

export interface PersonBusinessCardListItem {
    id: number;
    label: string;
    presetLabel: string;
}

export interface SendVCardInputModel {
    idBusinessCard: number;
    recipientEmail: string;
}