import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'valueToLabel'
})
export class ValueToLabelPipe implements PipeTransform {
    transform(val: any, mappings: any[]): any {
        const matchingMapping = mappings.find(mapping => mapping.value === val);
        return matchingMapping ? matchingMapping.label : val;
    }
}
