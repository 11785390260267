import { HttpClient, HttpErrorResponse, HttpHeaders,  HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export const GENERIC_DATA = 'genericData';

@Injectable()
export class GenericData {

    requestState$: Observable<RequestStartEndEvenArgs>;
    private requestStateSubject = new ReplaySubject<RequestStartEndEvenArgs>(0);

    constructor(
        private httpClient: HttpClient,
    ) {
        this.requestState$ = this.requestStateSubject.asObservable();
    }

    getData$<T>(url: string, params?: any, options?: any): Observable<{ model: T }> {
        if (this.validateUrl(url)) {
            const responseType = options?.responseType || 'json';
            return this.httpClient.get<{ model: T }>(url, { params, headers: this.authorizationHeaders(), responseType })
                .pipe(
                    map(response => {
                        return response;
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.handleUnauthorized(error, options);
                        return throwError(error);
                    })
                );
        }
        return throwError('Url cannot be null or empty');
    }

    postData$<T>(url: string, model?: any, options?: any): Observable<{ model: T }> {
        return this.post$<{ model: T }>(url, model, options);
    }

    postBlob$<T>(url: string, model?: any, options?: any): Observable<Blob> {
        return this.post$<Blob>(url, model, options);
    }

    deleteData$(url: string, reqData?: any, options?: any) {
        if (this.validateUrl(url)) {
            let params = reqData instanceof Object ? reqData : { id: reqData };
            return this.httpClient.delete(url, { params, headers: this.authorizationHeaders() })
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        this.handleUnauthorized(error, options);
                        return throwError(error.error);
                    })
                );
        }
        return throwError('Url cannot be null or empty');
    }

    authorizationHeaders(): HttpHeaders {
        let token = this.getCookie('__RequestVerificationToken');
        return new HttpHeaders({
            RequestVerificationToken: token && (token as string | string[]) || '',
        });
    }

    private post$<T>(url: string, model?: any, options?: any): Observable<T> {
        if (this.validateUrl(url)) {
            const responseType = options?.responseType || 'json';
            return this.httpClient.post(url, model, { headers: this.authorizationHeaders(), observe: 'response', reportProgress: true, responseType })
                .pipe(
                    map((response: HttpResponse<any>) => {
                        if (response.body && Array.isArray(response.body)) {
                            return [...response.body];
                        }
                        return response.body;
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.handleUnauthorized(error, options);
                        return throwError(error.error);
                    })
                );
        }
        return throwError('Url cannot be null or empty');
    }

    private validateUrl(url: string) {
        return (url != null && url.trim() !== '');
    }

    private handleUnauthorized(error: HttpErrorResponse, options = { ignoreUnauthorized: false }) {
        if (!options.ignoreUnauthorized) {
            if (error.status === 401) {
                (window as any).location = '/Account/LoginForms?ReturnUrl=' + encodeURI((window as any).location);
            }
        }
    }

    private getCookie(name: string) {
        let cookies: Array<string> = document.cookie.split(';');
        let length: number = cookies.length;
        let cookieName = `${name}=`;
        let cookie: string;

        for (let i: number = 0; i < length; i += 1) {
            cookie = cookies[i].replace(/^\s+/g, '');
            if (cookie.indexOf(cookieName) == 0) {
                return cookie.substring(cookieName.length, cookie.length);
            }
        }
        return '';
    }
}

export interface RequestStartEndEvenArgs {
    idRequest: number;
    active: boolean;
}
