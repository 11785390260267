import { Component, OnDestroy } from '@angular/core';
import { HeaderService } from '../../services/header.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-card-template',
   template: require('./card-template.component.html'),
})
export class CardTemplateComponent implements OnDestroy {

  private subscriptions: Subscription[] = [];
  public title: string = "";
  public showBackArrow: boolean = false;

  constructor(
    private headerService: HeaderService,
    private router: Router) {

    this.subscriptions.push(
      this.headerService.getTitle().subscribe((data) => {
        this.title = data;
      }),
      this.headerService.getBackArrow().subscribe((data) => {
        this.showBackArrow = data;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  backClicked() {
    this.router.navigate([this.router.url.split('/').slice(0, -1).join('/')]);
  }

  constructLogoutPath() {
    return `/Account/Logout?ReturnUrl=${this.router.url}`;
  }
}
