import { CommonModule } from "@angular/common";
import { SvgIconComponent } from "./svg-icon.component";
import { NgModule } from "@angular/core";


@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        SvgIconComponent
    ],
    entryComponents: [
        SvgIconComponent
    ],
    exports: [
        SvgIconComponent
    ],
})
export class SharedComponentsModule { }